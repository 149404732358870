////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(73, 75, 73, 1);
}
.bg-thirdColor {
  background-color: rgba(73, 75, 73, 1);
}
.bg-fourthColor {
  background-color: rgba(75, 190, 38, 1);
}
.bg-fifthColor {
  background-color: rgba(75, 190, 38, 0.5);
}
.bg-sixthColor {
  background-color: rgba(75, 190, 38, 1);
}
.bg-seventhColor {
  background-color: rgba(73, 75, 73, 0.5);
}
